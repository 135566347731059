/*
 * @Author: hz_swtc_zhourong hz_swtc_zhourong@xiqi.com
 * @Date: 2024-08-05 19:05:23
 * @LastEditors: hz_swtc_zhourong hz_swtc_zhourong@xiqi.com
 * @LastEditTime: 2024-09-06 22:03:04
 * @FilePath: \kpyunfsservicee:\git\topdigital\topdweb\user\src\request\http.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from  'axios';
import AppConfig from "../app.config";

// import {message} from 'antd';

const baseURL = (val) =>{
    // console.log(val)
    const baseVal = {
        'development':"https://wxapp.kuaipantech.com/TopDigital/index.php",//local 环境
        'production': AppConfig.INTER_BASE,//build 环境
    }
    return baseVal[val]
}

const http = axios.create({
    timeout:30000,
    baseURL:baseURL(process.env.NODE_ENV)
})

http.interceptors.request.use(config =>{
    // console.log('config---------------', config);

    config.headers['Content-Type'] = 'application/json';

    // let token = localStorage.getItem('token');
    // if(token) config.headers['Authorization'] = token;
    // if(token) config.headers['Authorization'] = `Bearer ${token}`;

    return config
}, err =>{
    return Promise.reject(err)
});

http.interceptors.response.use(res =>{
    if(res){
        // console.log('--------http-------------', res)
        return res
    }
},err =>{
    return Promise.reject(err)
})

export default http;
